$containter-width: 48%;

mp-expenses-page {
    @import '../../sass/variables';
    @import './ui/expensesSummary';
    @import './ui/numKeyboard';

    .mp-tools-bar {
        i {
            fill: black;
        }
    }

    display: block;
    min-height: 480px;

    .mp-tabs-navigation-left-item {
        min-width: 40px;
        box-sizing: border-box;
        text-align: center;
    }

    mp-accordion {
        .content {
            div {
                text-align: left;
                padding-left: 5px;

                span {
                    display: inline-block;
                    width: 80px;
                    padding-left: 5px;
                }
            }
        }
    }

    .inputs-container {
        display: flex;
        justify-content: center;
    }
}
