.mp-header {
    @import '../../sass/variables';

    user-select: none;

    &-icons {
        display: flex;
        justify-content: space-between;
        background-color: $global-gray-dark;

        li {
            float: left;

            a,
            button {
                display: inline-block;
                color: white;
                background-color: $global-gray-dark;

                &:hover {
                    background-color: $global-gray-very-dark;
                }
            }
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            overflow: hidden;
            background-color: $global-gray-dark;
        }

        &-right {
            white-space: nowrap;

            .header-user-name {
                display: none;

                @include breakpoint(small-mobile) {
                    display: inline-block;
                }
            }

            i {
                &:hover {
                    background-color: $global-gray-very-dark;
                }
            }

            button,
            div {
                display: inline-block;
                color: white;
            }

            div {
                padding: 0 8px;
                line-height: 40px;
            }

            img {
                box-sizing: border-box;
                margin: 2px;
                width: 36px;
                height: 36px;
                vertical-align: top;
                border-radius: 20px;
                border: solid 2px #595959;
                cursor: pointer;
            }
        }
    }

    &-user-menu {
        position: absolute;
        right: 0;
        width: 160px;
        background-color: $global-gray-dark;
        color: white;
        z-index: 999;

        div,
        a {
            display: block;
            padding: 0 8px;
            line-height: 40px;
            text-decoration: none;
            color: white;
            cursor: pointer;

            &:hover {
                background-color: $global-gray-very-dark;
            }

            &:visited {
                color: white;
            }
        }
    }
}
