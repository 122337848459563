.mp-tools-bar {
    display: flex;
    justify-content: space-between;
    text-align: right;
    color: white;

    &-left > .mp-tools-bar-left-name:first-child {
        margin-left: 16px;
    }
    &-left,
    &-right {
        display: inline-block;

        &-name {
            font-weight: 700;
            letter-spacing: 3px;
            text-transform: uppercase;
            line-height: 40px;
            vertical-align: middle;
        }
    }

    &-description {
        padding: 0 15px;
    }

    span {
        display: inline-block;
    }
}
