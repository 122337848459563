.mp-num-keyboard {
    width: 154px;

    &-category {
        line-height: 40px;
        text-align: center;
    }

    .mp-date-input {
        text-align: center;
        margin: 2px 0;

        input {
            width: 100%;
        }
    }

    .mp-text-input {
        margin: 0;
    }

    &-top {
        display: flex;
    }

    &-numbers-container {
        display: grid;
        padding: 2px 0;
        grid-template-columns: repeat(auto-fill, 50px);
        justify-content: space-evenly;
        grid-gap: 2px;

        button {
            margin: 0;
            width: 50px;
            height: 50px;
        }
    }

    &-footer {
        text-align: center;

        input {
            margin-bottom: 2px;
        }

        button {
            margin: 0;
            width: 100%;
            height: 50px;
        }
    }
}
