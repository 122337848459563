.mp-movie-details-window {
    @import '../../../../sass/variables';

    &-content {
        img {
            display: block;
            margin: 0 auto;
            height: 285px;
            width: 200px;
            border: solid 1px;
            cursor: pointer;
        }

        &-input-container {
            position: relative;
            margin: 10px auto;

            &-row-double {
                text-align: center;
                line-height: 40px;
                margin-bottom: 5px;

                span {
                    margin-right: 8px;
                }

                .mp-text-input {
                    display: inline-block;
                    width: 54px;

                    input {
                        text-align: center;
                    }
                }

                input[type='checkbox'] {
                    margin-right: 16px;
                    height: 20px;
                    width: 20px;
                    vertical-align: middle;
                }
            }
        }

        .response-success {
            text-align: center;
            color: green;
        }

        .response-error {
            text-align: center;
            color: red;
        }
    }
}
