@import '../../../../../sass/variables';

.mp-context-menu-modal {
    position: fixed;
    min-width: 150px;
    min-height: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: solid 1px black;
    border-bottom: none;
    background-color: white;
    z-index: $background-z-index + 1;

    &-option,
    &-title {
        height: 40px;
        line-height: 40px;
        padding: 0 8px;
        cursor: pointer;
        border-bottom: solid 1px black;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 90vw;
    }

    &-title {
        text-align: center;
        font-weight: bold;
        cursor: auto;
    }
}
