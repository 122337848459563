.mp-bucket-list-page {
    @import '../../../sass/variables';

    display: flex;
    flex-direction: column;
    flex-grow: 1;

    a {
        color: #111;
        text-decoration: none;
    }

    .category-title {
        padding: 10px;
        text-transform: uppercase;
        font-size: 20px;
        text-align: center;

        span {
            margin: 0 20px;
        }

        i {
            padding: 10px;
        }
    }

    .footer {
        height: 15px;
    }
    @import './categoriesListView';
    @import './itemListView';
    @import './addEditItemView';
}
