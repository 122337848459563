.cash-envelope-page {
    text-align: center;

    .name-container {
        margin: auto auto 16px;
        max-width: 300px;
    }

    .select-subtract-or-add {
        margin-top: 8px;

        i {
            margin-right: 8px;
        }
    }

    .value-to-subtract-or-add {
        margin: 16px 8px 8px;
    }

    .nominal-value {
        margin-top: 16px;

        button {
            margin-left: 0;
        }
    }
}
