.mp-check-box {
    margin: 8px;
    text-align: left;

    &-title {
        @include input-title;
    }

    input {
        box-sizing: border-box;
        margin: 0 8px;
        width: 20px;
        height: 30px;
        cursor: pointer;
    }
}
