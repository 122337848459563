.calendar-details-list {
    &__item {
        margin: 5px 8px;

        .other-user-date {
            color: #a19f9f;

            i {
                fill: #a19f9f;
            }
        }

        i {
            width: 10px;
            max-height: 10px;
            margin-right: 4px;
        }
    }
}
