.mp-fonts-page {
    .font-item {
        display: flex;
        border-bottom: solid 1px lightgray;

        div {
            margin-left: 8px;
            width: 200px;
        }
        i {
            width: 40px;
            text-align: center;

            svg {
                max-height: 40px;
                max-width: 40px;
            }
        }
        * {
            line-height: 40px;
        }
    }
}
