.mp-wish-list-page {
    @import '../../../sass/variables';

    i {
        fill: black;
    }

    &-item {
        box-sizing: border-box;
        border: solid 1px black;
        margin: 10px 0;

        &-title {
            display: flex;
            justify-content: space-between;
            line-height: 40px;
            height: 40px;
            text-decoration: none;
            color: black;

            &-left {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                .link {
                    cursor: not-allowed;
                }

                a {
                    .link {
                        fill: #0066cc;
                        cursor: pointer;
                    }

                    &:visited {
                        fill: #0066cc;
                    }
                }

                span {
                    font-weight: 600;
                }
            }

            &-right {
                min-width: 90px;
                text-align: right;
            }
        }

        &-description {
            margin: 6px 8px;
            font-size: 10px;
        }

        .button-container {
            text-align: center;
        }
    }
}
