@import './vars';

.schedule-tile {
    display: inline-grid;
    margin: 0;
    padding: 0;
    min-width: 95px;
    max-width: 95px;
    min-height: 64px;
    max-height: 64px;
    border: solid 2px $color_1;
    border-radius: 4px;
    text-align: center;
    color: white;
    text-decoration: none;
    background-color: transparent;

    &-time {
        margin-top: 6px;
        font-size: 24px;
    }

    &[selected] {
        background-color: $color_1;
    }
}
