@mixin button-base($size) {
    box-sizing: border-box;
    margin: 8px;
    padding: 0 8px;
    height: $size;
    min-width: $size;
    line-height: $size;

    &.no-margin {
        margin: 0;
    }
}

.mp-button {
    @include button-base(40px);
}

.mp-button-small {
    @include button-base(30px);

    border-radius: 4px;
}
