@import '../../sass/variables';

.mp-footer {
    margin-top: auto;
    min-height: 40px;
    background-color: $global-gray;
    text-align: center;
    color: $global-gray-light;

    a {
        padding: 0 8px;
        line-height: 40px;
        text-decoration: none;
        color: $global-white;

        i {
            margin-right: 8px;
        }
    }
}
