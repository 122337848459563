.page {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.filters {
    .multiSelect {
        display: flex;
    }

    .dateFilters {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        .filtersButtons {
            display: flex;
            flex-wrap: nowrap;
            gap: 8px;

            button {
                display: flex;
                margin: 0;
                align-self: center;
            }
        }

        & > * {
            margin: 0;
        }
    }
}
