$mobile-width: 360px;
$tablet-width: 600px;
$global-red: #a20202;
$global-white: rgb(248, 241, 241);
$global-gray-very-dark: #111111;
$global-gray-dark: #333333;
$global-gray: #363636;
$global-gray-light: #7a8086;
$global-gray-very-light: #dadada;
$global-orange: sandybrown;
$global-background-blue: #add8e6;
$global-green-light: #8ee38e;

$font-color-red: #a20202;
$font-color-green: #509d50;

$page-max-width: 940px;

$link-color: dodgerblue;

$rating-star-color: #ffc200;

$light-red: #ff7578;
$dark-red: #ff6064;
$light-orange: #ffc575;
$dark-orange: #ffbb60;
$light-green: #aefdac;
$dark-green: #9afc98;

$background-z-index: 9998;

@mixin icon-button {
    padding: 0;
    width: 40px;
    line-height: 40px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    color: $global-white;
    background-color: $global-gray;
}

@mixin input-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
    text-align: left;
    font-size: 12px;
}

//$breakpoints: (
//    'mobile': 360px
//) !default;
//
//@mixin view($breakpoint) {
//    @if map-has-key($breakpoints, $breakpoint) {
//        @media (max-width: map-get($breakpoints, $breakpoint)) {
//            @content;
//        }
//    }
//}

@mixin fullWidth() {
    min-width: 100%;
    max-width: 100%;

    @media (min-width: $page-max-width) {
        min-width: $page-max-width;
        max-width: $page-max-width;
    }
}

@mixin breakpoint($point) {
    @if $point == small-mobile {
        @media (min-width: 400px) {
            @content;
        }
    } @else if $point == desktop {
        @media (min-width: 600px) {
            @content;
        }
    } @else {
        @media (min-width: $point) {
            @content;
        }
    }
}
