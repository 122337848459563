.movie-row {
    display: flex;
    margin: 8px 0;
    height: 100px;
    justify-content: space-between;
    background: #3f3e3e;

    &-poster {
        min-width: 70px;
        max-width: 70px;

        img {
            height: 100%;
            width: 70px;
        }
    }

    &-title {
        width: 100%;
        text-align: center;

        &-top {
            background-color: #494c4f;
            min-height: 14px;
            max-height: 14px;
            line-height: 14px;
            padding: 0 8px;
            color: white;
            font-size: 10px;
            text-align: right;
        }

        &-bottom {
            min-height: 82px; // 14 + 2*2
            max-height: 82px;

            display: flex;
            align-items: center;
            justify-content: center;
        }

        a,
        span {
            display: block;
            color: white;
            text-decoration: none;
            font-weight: 600;
            cursor: pointer;
        }
    }

    &-right {
        min-width: 40px;
        max-width: 40px;

        i {
            background-color: #494c4f;
            height: 50px;

            &:hover {
                background-color: #2e2f31;
            }
        }
    }
}
