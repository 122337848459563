.mp-costs-estimate-page {
    font-family: Lato, sans-serif, Helvetica, sans-serif;

    @import '../../../sass/variables';
    @import './costsEstimateWarnings';
    @import './costsEstimateSection';

    a {
        color: $link-color;

        &:visited {
            color: $link-color;
        }
    }

    .total-cost {
        margin: 8px;
    }
}
