.mp-series-page {
    @import '../../sass/variables';

    &-items {
        margin: 0 8px;
    }

    .air-date-modal-content {
        margin: auto;
    }
}
