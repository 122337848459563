.mp-subscriptions-page {
    text-align: center;

    .subscription {
        margin: 0 auto;
        padding: 0 8px;
        display: flex;
        justify-content: space-between;
        line-height: 25px;
        max-width: 450px;

        div {
            span {
                &:nth-child(2) {
                    display: inline-block;
                    text-align: right;
                    min-width: 70px;
                    max-width: 70px;
                }
            }
        }
    }
}
