@import './vars';

.mp-cinema-program {
    @import '../../../sass/variables';
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: #000000;

    .mp-tabs-navigation {
        justify-content: center !important;

        &-left-item:nth-child(2) {
            min-width: 100px;
        }
    }

    .empty-program {
        margin: 24px 8px;
        font-weight: 400;
        letter-spacing: 0.14em;
        text-transform: uppercase;
        line-height: 24px;
        font-size: 18px;
        text-align: center;
        color: white;
    }

    &-footer {
        @include fullWidth;

        position: fixed;
        line-height: 40px;
        bottom: 0;
        text-align: center;
        background-color: $global-gray-dark;
        border-top: solid 1px $global-gray-light;
        color: white;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }

    @import './scheduleModal';
    @import './movieInfoBox';
    @import './scheduleTile';
}
