.mp-image-crop {
    text-align: center;
    border: solid 1px gray;

    &-image {
        margin: 8px;
    }

    &-footer {
        button {
            margin: 0 8px 8px;
        }
    }
}
