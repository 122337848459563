.ingredient-edit-row {
    @import '../../../sass/variables';

    display: flex;
    margin: 0 8px;

    &.row-ok {
        input[type='text'] {
            background-color: $global-green-light;
        }
    }

    .mp-suggestion-list {
        flex-grow: 1;
        margin: 1px;
    }

    .mp-text-input {
        margin: 1px;

        &[data-name='value'] {
            width: 40px;
        }

        &[data-name='unit'] {
            width: 60px;
        }
    }

    button {
        margin: 1px;
        min-width: 71px;
    }
}
