row-component {
    display: block;

    span {
        display: inline-block;
        padding-right: 5px;
        width: 90px;
        text-align: right;
    }

    .validation-error {
        margin-left: 90px;
        text-transform: uppercase;
        font-size: 8px;
        color: red;
    }
}
