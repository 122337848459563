.mp-service-error {
    &-background {
        position: fixed;
        background-color: black;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 50%;
        z-index: 99998;
    }

    &-window {
        position: fixed;
        background-color: white;
        width: 300px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99999;
        border: solid 1px black;
        text-align: center;

        &-title {
            line-height: 40px;
            background-color: $global-gray-dark;
            color: white;
            font-weight: 700;
            text-transform: uppercase;
        }

        &-content {
            position: relative;

            div {
                margin: 8px;
            }

            .mp-svg-icon {
                width: 220px;
                margin: 8px 0;
                fill: #c4c4c4;
            }
        }

        &-bottom {
            padding: 0;
        }
    }
}
