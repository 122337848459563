@import './vars';

.mp-common-modal-content {
    background-color: black;
    color: white;

    .schedule-modal {
        .screen {
            font-size: 20px;
            text-align: center;
        }

        .buy-ticket {
            text-align: center;

            a {
                color: white;

                &:visited {
                    color: white;
                }

                span {
                    display: inline-block;
                    margin: 8px;
                    padding: 4px;
                    border: solid 2px $color_1;
                    border-radius: 5px;
                }
            }
        }

        .schedule {
            margin-top: 8px;
            text-align: center;

            span {
                margin-left: 40px;
            }
        }

        .cast {
            a,
            a:visited {
                text-decoration: underline;
                color: #3e91be;
            }

            a {
                display: inline-block;
                padding-right: 8px;
                white-space: nowrap;
            }
        }

        .description {
            margin-bottom: 8px;
            font-size: 12px;
            color: #b4b2b2;
        }
    }
}
