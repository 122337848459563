clue-icon {
    display: inline-block;

    .circle-container {
        display: inline-block;
        position: relative;
        width: 23px;
        height: 20px;
        margin: 0 8px 0 0;

        .circle-1,
        .circle-2,
        .circle-3 {
            display: block;
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 5px;
        }

        &.n-3 {
            .circle-1 {
                left: 7px;
            }

            .circle-2 {
                bottom: 0;
            }

            .circle-3 {
                bottom: 0;
                right: 0;
            }
        }

        &.n-2 {
            .circle-1 {
                right: 0;
            }

            .circle-2 {
                bottom: 0;
                right: 7px;
            }
        }

        &.n-1 {
            .circle-1 {
                right: 0;
            }
        }
    }
}
