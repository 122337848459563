mp-games-page {
    .preview-card-modal {
        .preview {
            margin: 5px 0;
            border: solid 1px black;

            .question {
                padding: 10px;
                font-weight: bold;
            }

            .c3,
            .c2,
            .c1 {
                text-align: left;
                padding: 0 10px 20px;

                @include breakpoint(desktop) {
                    text-align: center;
                }
            }

            .answer {
                font-weight: bold;

                i {
                    margin: 0 5px 0 10px;
                    width: 14px;
                }
            }

            .number {
                margin: 0 5px 5px;
                text-align: right;
                font-size: 8px;
            }
        }
    }
}
