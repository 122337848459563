@import '../../../../../sass/variables';

.mp-grayed-out-background {
    position: fixed;
    background-color: black;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 50%;
    z-index: $background-z-index;
}
