.mp-series-item {
    display: flex;
    justify-content: space-between;
    max-width: 500px;
    margin: 8px auto;
    line-height: 40px;
    border-radius: 5px;
    color: white;
    white-space: nowrap;

    &-left {
        overflow: hidden;
        text-overflow: ellipsis;

        .series {
            &-day {
                display: inline-block;
                width: 35px;
                text-align: center;
            }

            &-episode {
                display: inline-block;
                width: 75px;
            }
        }
    }

    &-right {
        min-width: 40px;
        text-align: right;

        &.with-label {
            min-width: 100px;
        }
    }

    .new-episode-label {
        position: relative;
        display: inline-block;
        top: -2px;
        width: 55px;
        height: 12px;
        line-height: 12px;
        text-align: center;
        font-size: 9px;
    }

    .new-episode {
        background-color: rgb(39, 167, 39);
    }

    .no-new-episode {
        background-color: indianred;
    }
}
