.mp-settings-page {
    $font-color: #5d5e62;

    @import '../../sass/variables';
    @import './ui/tileItemsSettings';
    @import './ui/customInput';

    margin: 0 32px;
    user-select: none;

    section {
        margin: 8px;
        border-bottom: solid 1px #aaacb6;
        padding-bottom: 16px;

        .settings-title {
            padding: 8px 8px 16px;
            text-align: center;
            line-height: 20px;
            font-size: 18px;
            font-weight: 600;
            color: $font-color;
        }
    }
}
