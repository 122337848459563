.mp-links-page {
    &-link {
        margin: 0 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        a {
            text-decoration: none;
            line-height: 40px;
            color: #3498db;
        }
    }
}
