.section {
    &-header {
        display: flex;
        padding: 0 8px;
        justify-content: space-between;
        background-color: $global-gray-dark;
        color: $global-white;
        font-weight: bold;
        gap: 8px;
        line-height: 30px;
        border-bottom: solid 1px $global-white;
        cursor: pointer;

        &-title {
            display: flex;
            gap: 8px;
            white-space: nowrap;
            overflow: hidden;

            span {
                overflow: inherit;
                text-overflow: ellipsis;
            }
        }

        &-cost {
            white-space: nowrap;
            flex-shrink: 0;
        }

        .triangle-exclamation,
        .pencil {
            display: flex;
            flex-shrink: 0;
        }

        .triangle-exclamation {
            width: 20px;

            svg {
                fill: $global-orange;
            }
        }

        .pencil {
            width: 16px;
            cursor: pointer;

            svg {
                fill: $global-white;
            }
        }
    }
}

.item {
    display: flex;
    gap: 8px;
    padding: 12px 8px;
    border-bottom: 1px solid $global-gray-very-light;
    font-weight: 100;

    &-left {
        display: inherit;
        flex-direction: column;
        flex-grow: 1;
        gap: 8px;

        &-details {
            margin-top: auto;

            & > div {
                & > * {
                    &:nth-child(1) {
                        display: inline-block;
                        width: 48px;
                    }
                    &:nth-child(2) {
                        //background: blue;
                    }
                }
            }
        }

        .pencil,
        .camera-retro,
        .triangle-exclamation {
            width: 12px;
            margin-right: 8px;
            cursor: pointer;
        }

        .pencil,
        .camera-retro {
            width: 16px;
        }

        .triangle-exclamation {
            svg {
                fill: $global-orange;
            }
        }
    }

    &-right {
        img {
            box-sizing: border-box;
            max-width: 100px;
            max-height: 100px;
            min-width: 100px;
            min-height: 100px;
            border: solid 1px $global-gray-very-light;
            object-fit: contain;
        }
    }
}
