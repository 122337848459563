.mp-check-row {
    @import '../../../sass/variables';

    $colorLight: #b4cbd6;
    $colorDark: #8da4af;
    $beige: #cbbfb3;

    $maintainer_1_dark: #95bbd9;
    $maintainer_2_dark: #d59393;

    margin: 1px 0;
    display: flex;
    cursor: pointer;

    &.maintainer-id {
        &-1 {
            background-color: #d4e6f0;

            i {
                fill: $maintainer_1_dark;
            }
        }
        &-2 {
            background-color: #eed1d1;

            i {
                fill: $maintainer_2_dark;
            }
        }
    }

    div:first-of-type {
        text-align: center;
        min-width: 30px;
        max-width: 30px;
    }

    div:last-of-type {
        width: calc(100% - 38px);
        min-height: 40px;
        box-sizing: border-box;
        border-bottom: solid 1px $beige;

        span {
            display: inline-block;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            line-height: 20px;

            &.after-deadline {
                color: $global-red;
                font-weight: 600;
            }
        }
    }

    i {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        width: 15px;
        max-height: 16px;
        fill: $colorLight;
    }

    &-checked {
        @extend .mp-check-row;

        &.maintainer-id {
            &-1 {
                color: $maintainer_1_dark;
            }
            &-2 {
                color: $maintainer_2_dark;
            }
        }

        i {
            fill: $colorDark;
        }
        color: lightgray;
    }
}
