.calendar-preview-dates {
    .mp-tools-bar {
        i {
            fill: black;
        }
    }
    &-date {
        text-align: center;
        font-size: 18px;
    }

    &-rows {
        margin: auto;
        max-width: 500px;

        .preview-row {
            display: flex;
            justify-content: space-between;
            margin: 8px;

            &.other-user-date {
                color: #a19f9f;

                i {
                    border: solid 1px #a19f9f;
                    svg {
                        fill: #a19f9f;
                    }
                }
            }

            &-icons {
                margin-left: 8px;
                white-space: nowrap;
            }

            i {
                box-sizing: border-box;
                border: solid 1px black;

                svg {
                    fill: black;
                }
            }

            span {
                display: inline-block;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                line-height: 20px;
            }
        }
    }
}
