.warnings {
    @import '../../../sass/variables';

    padding: 8px;

    $icon-size: 20px;

    &-header {
        display: flex;
        font-size: $icon-size;
        line-height: $icon-size;
        justify-content: center;

        svg {
            fill: $global-orange;
            margin-left: 8px;
            width: $icon-size;
            height: $icon-size;
        }
    }

    &-body {
        font-size: 14px;

        &-message {
            margin: 8px 0;

            & > div:nth-child(1) {
                display: inline-block;
                cursor: pointer;
            }

            & > div:nth-child(2) {
                font-weight: 100;
                color: $global-gray-light;
            }
        }
    }
}
