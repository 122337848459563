.mp-shopping-list-page {
    @import '../../../sass/variables';

    .shopping-suggestion-list {
        margin: 8px auto;
        padding: 0 8px;

        @include breakpoint(desktop) {
            max-width: 600px;
        }
    }

    &-title {
        position: relative;
        background-color: gray;
        padding: 10px;
        font-size: 16px;
        margin-bottom: 15px;
        color: white;

        span {
            margin-right: 16px;
            cursor: pointer;

            &.selected {
                text-decoration: underline;
            }
        }

        i {
            margin-left: 8px;
            font-size: 10px;
            cursor: pointer;
            user-select: none;
        }

        &-add-button {
            position: absolute;
            right: 5px;
            top: 5px;
        }
    }

    &-refresh {
        margin-top: 8px;
        text-align: center;
    }
}
