.mp-common-modal {
    @import '../../../sass/variables';
    $minModalWidth: 360px;

    position: fixed;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    min-width: 100vw;
    max-width: 100vw;
    min-height: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: solid 1px black;
    background-color: white;
    z-index: $background-z-index + 1;

    &.modal-size {
        &-small {
            min-width: $minModalWidth;
            max-width: $minModalWidth;
            min-height: 200px;
            max-height: 300px;
        }

        @include breakpoint(desktop) {
            &-medium {
                height: 100%;
                min-width: 600px;
                max-width: 600px;
                min-height: 300px;
                max-height: 90vh;
            }

            &-big {
                height: 100%;
                min-width: $page-max-width;
                max-width: $page-max-width;
            }
        }
    }

    &-title {
        position: relative;
        padding: 0 40px;
        min-height: 40px;
        max-height: 40px;
        line-height: 40px;
        text-transform: uppercase;
        text-align: center;
        font-size: 15px;
        font-weight: 700;
        color: $global-white;
        background-color: $global-gray-dark;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        i {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    &-content {
        display: flex;
        padding: 8px;
        flex-grow: 1;
        flex-direction: column;
        overflow-y: auto;
    }

    &-footer {
        margin: auto auto 8px;

        button {
            margin: 0 0 0 8px;

            &:first-of-type {
                margin: 0;
            }
        }
    }
}
