.mp-simple-toolbar {
    display: flex;
    justify-content: space-between;
    user-select: none;

    &-name {
        display: grid;
        place-items: center;
        line-height: 16px;
        font-size: 12px;
        min-height: 40px;

        span {
            color: white;
            font-weight: 700;
            letter-spacing: 3px;
            text-transform: uppercase;
            vertical-align: middle;
        }
    }
}
