@import './movieInfoBoxSkeleton';
@import './vars';

.movie-info {
    @import './ratingBox';

    color: white;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: solid 1px gray;

    &-top {
        display: flex;

        &-left {
            margin: 8px;
            min-width: 115px;
            max-width: 115px;
            min-height: 172px;
            max-height: 172px;

            .promo-label-container {
                position: absolute;

                span {
                    display: block;
                    font-size: 12px;
                    padding: 2px 4px;
                    font-weight: bold;
                    background-color: $color_1;

                    &.superhit {
                        background-color: orange;
                    }
                }
            }

            img {
                max-width: 100%;
                max-height: 100%;
                cursor: pointer;
            }
        }

        &-right {
            margin: 8px;

            img {
                cursor: pointer;
            }

            &-title {
                font-weight: 400;
                letter-spacing: 0.14em;
                text-transform: uppercase;
                line-height: 1.1;
                font-size: 18px;

                a {
                    color: white;
                    text-decoration: none;
                }

                i {
                    min-width: 40px;
                    min-height: 40px;
                    line-height: 40px;
                    text-align: center;
                }

                &-buttons {
                    margin-left: -7px;

                    img {
                        padding: 8px 8px 12px;
                        max-height: 20px;
                        vertical-align: middle;
                    }
                }
            }

            &-params {
                margin-top: 24px;
                color: gray;

                span {
                    white-space: nowrap;
                }
            }
        }
    }

    &-bottom {
        margin: 8px;

        &-day {
            margin: 8px 0;
            text-align: center;
        }

        &-grid {
            display: grid;
            grid-template-columns: repeat(auto-fill, 95px);
            justify-content: space-evenly;
            grid-gap: 8px;

            .schedule-tile-version {
                font-size: 10px;
            }
        }
    }
}
