.mp-in-cinema-movies-tab {
    &-grid {
        @import '../../../../sass/variables';

        display: grid;
        grid-template-columns: repeat(auto-fill, 100%);
        padding: 2px;
        box-sizing: border-box;
        justify-content: center;
        grid-gap: 2px;

        @include breakpoint(desktop) {
            grid-template-columns: repeat(auto-fill, calc(50% - 1px));
        }

        @import '../common/movieRow';

        .movie-row {
            margin: 0;
        }
    }
}
