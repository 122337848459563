@import '../../../sass/variables';

.search {
    margin: 16px auto;
    max-width: 500px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 85px);
    justify-content: space-evenly;
    grid-gap: 5px;

    @include breakpoint(desktop) {
        grid-template-columns: repeat(auto-fill, 185px);
    }
}
