.darts-page-game-progress {
    @import 'vars';
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: $board-bg-color;
    color: $font-color;

    @import './dartsScoreBoard';

    .end-game {
        text-align: center;
    }

    .darts-keyboard {
        margin: auto auto 4px;
        max-width: 308px;

        .mp-button {
            margin: 2px;

            &.selected {
                background-color: $keyboard-button-selected;
            }
        }

        &-extra {
            display: flex;
            justify-content: space-between;

            button {
                padding: 0 7px;
            }

            div {
                display: flex;
            }
        }
    }
}
