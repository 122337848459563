mp-accordion {
    display: block;

    .accordion-item {
        border: solid 1px;
        border-bottom: 0;

        &:last-child {
            border-bottom: solid 1px;
        }

        .label {
            background-color: bisque;
            padding: 10px;
            cursor: pointer;
        }

        .content {
            display: none;
            text-align: center;
            background-color: beige;
            padding: 5px;
        }

        &.active {
            .content {
                display: block;
            }
        }
    }
}
