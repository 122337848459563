.nav {
    @import '../../../../../sass/variables';

    display: flex;
    line-height: 40px;
    max-height: 40px;
    background: #f0ece1;

    * {
        color: $global-gray-very-dark;
    }
}
