.mp-calendar {
    $cellSize: 24px;

    padding: 5px;
    border: solid 1px black;
    width: fit-content;
    user-select: none;

    &-header {
        margin-bottom: 5px;
        font-weight: bold;
    }

    &-days {
        font-weight: bold;
    }

    &-days,
    &-grid {
        display: grid;
        grid-template-columns: repeat(7, $cellSize);
    }

    &-grid {
        > div {
            line-height: $cellSize;
            max-height: $cellSize;

            &[data-cell] {
                cursor: pointer;
            }
            &:not([data-cell]) {
                visibility: hidden;
            }
        }
    }
}
