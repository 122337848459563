.mp-near-events {
    @import '../../../sass/variables';

    border-bottom: solid 1px $global-gray-light;
    overflow-x: auto;
    line-height: 10px;
    background-color: $global-gray-dark;

    div {
        display: inline-block;
        padding: 0 4px;
        line-height: inherit;
        font-size: 9px;
        color: $global-white;
        white-space: nowrap;
    }
}
