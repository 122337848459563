.sushi-page {
    @import '../../../sass/variables';
    $filter-font-size: 13px;

    background-color: white;

    &-toolbar {
        display: flex;
        justify-content: space-between;
        height: 40px;
        line-height: 40px;
        color: $global-white;
        background-color: $global-gray-dark;

        &-filter {
            button {
                position: relative;
                top: -1px;
                height: 40px;
            }

            ul {
                position: relative;
                top: -1px;
                margin: 0;
                padding: 0;
                z-index: 1;

                a {
                    text-decoration: none;

                    :hover {
                        background-color: #111;
                    }
                }

                li {
                    display: flex;
                    justify-content: space-between;
                    font-size: $filter-font-size;
                    padding: 0 10px;
                    box-sizing: border-box;
                    background-color: $global-gray-dark;
                    color: $global-white;
                    list-style-type: none;
                    text-align: center;
                    cursor: pointer;

                    .rating-stars {
                        margin-left: 8px;

                        i {
                            width: 16px;
                            vertical-align: middle;

                            svg {
                                fill: white;
                            }
                        }
                    }
                }
            }
        }
    }

    &__item {
        margin: 20px;

        &__name {
            display: flex;
            justify-content: space-between;
            position: relative;
            margin-bottom: 2px;
            font-size: 12px;
            font-weight: 600;
            cursor: pointer;
        }

        &__rating {
            display: inline-block;

            span {
                margin-left: 8px;
            }

            &__k {
                color: pink;

                i {
                    width: 14px;
                    vertical-align: middle;
                    fill: pink;
                }

                &.disabled {
                    color: $global-gray-very-light;
                }
            }

            &__m {
                color: royalblue;

                i {
                    width: 14px;
                    vertical-align: middle;
                    fill: royalblue;
                }

                &.disabled {
                    color: $global-gray-very-light;
                }
            }
        }

        &__ingredienst {
            font-size: 11px;
            color: rgb(63, 62, 62);
        }
    }
}
