.mp-service-error-background {
  position: fixed;
  background-color: black;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 50%;
  z-index: 99998;
}
.mp-service-error-window {
  position: fixed;
  background-color: white;
  width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  border: solid 1px black;
  text-align: center;
}
.mp-service-error-window-title {
  line-height: 40px;
  background-color: #333333;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
}
.mp-service-error-window-content {
  position: relative;
}
.mp-service-error-window-content div {
  margin: 8px;
}
.mp-service-error-window-content .mp-svg-icon {
  width: 220px;
  margin: 8px 0;
  fill: #c4c4c4;
}
.mp-service-error-window-bottom {
  padding: 0;
}

.mp-rating-stars i {
  width: 16px;
  vertical-align: middle;
}
.mp-rating-stars i svg {
  fill: black;
}

.mp-text-area {
  margin: 8px;
}
.mp-text-area-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  text-align: left;
  font-size: 12px;
}
.mp-text-area textarea {
  box-sizing: border-box;
  width: 100%;
  line-height: 13px;
  min-height: 60px;
  resize: none;
}

.select-single-icon {
  margin: 8px;
}
.select-single-icon-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  text-align: left;
  font-size: 12px;
}
.select-single-icon i {
  box-sizing: border-box;
  cursor: pointer;
}
.select-single-icon i.selected {
  border: solid 2px black;
}
.select-single-icon i.selected.select-item-disabled {
  border: solid 2px gray;
}
.select-single-icon i.select-item-disabled svg {
  fill: gray;
}
.select-single-icon i svg {
  fill: black;
}

.mp-check-box {
  margin: 8px;
  text-align: left;
}
.mp-check-box-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  text-align: left;
  font-size: 12px;
}
.mp-check-box input {
  box-sizing: border-box;
  margin: 0 8px;
  width: 20px;
  height: 30px;
  cursor: pointer;
}

.mp-date-input {
  margin: 8px;
  text-align: left;
}
.mp-date-input-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  text-align: left;
  font-size: 12px;
}
.mp-date-input input {
  min-width: 132px;
  box-sizing: border-box;
  height: 40px;
  cursor: pointer;
}

.mp-time-input {
  margin: 8px;
}
.mp-time-input-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  text-align: left;
  font-size: 12px;
}
.mp-time-input input {
  box-sizing: border-box;
  width: 100%;
  line-height: 40px;
  height: 40px;
  padding: 0 4px;
}

mp-accordion {
  display: block;
}
mp-accordion .accordion-item {
  border: solid 1px;
  border-bottom: 0;
}
mp-accordion .accordion-item:last-child {
  border-bottom: solid 1px;
}
mp-accordion .accordion-item .label {
  background-color: bisque;
  padding: 10px;
  cursor: pointer;
}
mp-accordion .accordion-item .content {
  display: none;
  text-align: center;
  background-color: beige;
  padding: 5px;
}
mp-accordion .accordion-item.active .content {
  display: block;
}

.mp-accordion-item-header {
  line-height: 16px;
  padding: 12px;
  background-color: cornflowerblue;
  cursor: pointer;
}
.mp-accordion-item-header > div {
  width: calc(100% - 16px);
  display: inline-block;
}
.mp-accordion-item-content {
  line-height: 16px;
  padding: 0 12px;
  background-color: #a0c1f6;
}

.calendar-preview-dates .mp-tools-bar i {
  fill: black;
}
.calendar-preview-dates-date {
  text-align: center;
  font-size: 18px;
}
.calendar-preview-dates-rows {
  margin: auto;
  max-width: 500px;
}
.calendar-preview-dates-rows .preview-row {
  display: flex;
  justify-content: space-between;
  margin: 8px;
}
.calendar-preview-dates-rows .preview-row.other-user-date {
  color: #a19f9f;
}
.calendar-preview-dates-rows .preview-row.other-user-date i {
  border: solid 1px #a19f9f;
}
.calendar-preview-dates-rows .preview-row.other-user-date i svg {
  fill: #a19f9f;
}
.calendar-preview-dates-rows .preview-row-icons {
  margin-left: 8px;
  white-space: nowrap;
}
.calendar-preview-dates-rows .preview-row i {
  box-sizing: border-box;
  border: solid 1px black;
}
.calendar-preview-dates-rows .preview-row i svg {
  fill: black;
}
.calendar-preview-dates-rows .preview-row span {
  display: inline-block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  line-height: 20px;
}

.calendar-details-list__item {
  margin: 5px 8px;
}
.calendar-details-list__item .other-user-date {
  color: #a19f9f;
}
.calendar-details-list__item .other-user-date i {
  fill: #a19f9f;
}
.calendar-details-list__item i {
  width: 10px;
  max-height: 10px;
  margin-right: 4px;
}

.mp-calendar-dates {
  margin: 10px auto;
  display: block;
  position: relative;
  width: 315px;
  user-select: none;
}
.mp-calendar-dates .calendar-navigation-bar {
  text-align: center;
  background-color: rebeccapurple;
  line-height: 40px;
  color: white;
}
.mp-calendar-dates .calendar-navigation-bar span {
  display: inline-block;
  width: 150px;
}
.mp-calendar-dates .calendar-navigation-bar a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  color: rgb(248, 241, 241);
  text-decoration: none;
}
.mp-calendar-dates .calendar-navigation-bar a:hover {
  background-color: rgb(121, 66, 175);
}
.mp-calendar-dates .calendar-grid {
  background-color: white;
}
.mp-calendar-dates .calendar-grid a {
  display: inline-block;
  position: relative;
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: black;
  cursor: pointer;
  text-decoration: none;
}
.mp-calendar-dates .calendar-grid-row {
  display: flex;
  justify-content: space-around;
}
.mp-calendar-dates a.cell-event {
  color: rgb(121, 66, 175);
  font-weight: bold;
}
.mp-calendar-dates a.cell-event.cell-only-not-own {
  color: rgb(221, 203, 238);
}
.mp-calendar-dates a.cell-event.cell-only-not-own:after {
  background: rgb(221, 203, 238);
}
.mp-calendar-dates a.cell-event:after {
  position: absolute;
  content: "";
  bottom: 3px;
  left: 3px;
  background: rgb(121, 66, 175);
  width: 34px;
  height: 4px;
  border-radius: 3px;
}
.mp-calendar-dates a.cell-current-day {
  box-shadow: 0 0 0 2px red inset;
}
.mp-calendar-dates a.cell-other-month {
  color: #b6b4b4;
  font-weight: normal;
}
.mp-calendar-dates a.cell-other-month.cell-only-not-own {
  color: #b6b4b4;
}
.mp-calendar-dates a.cell-other-month.cell-only-not-own:after {
  background: #efeded !important;
}
.mp-calendar-dates a.cell-other-month.cell-event:after {
  background-color: #c6c6c6;
}

.mp-header {
  user-select: none;
}
.mp-header-icons {
  display: flex;
  justify-content: space-between;
  background-color: #333333;
}
.mp-header-icons li {
  float: left;
}
.mp-header-icons li a,
.mp-header-icons li button {
  display: inline-block;
  color: white;
  background-color: #333333;
}
.mp-header-icons li a:hover,
.mp-header-icons li button:hover {
  background-color: #111111;
}
.mp-header-icons ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333333;
}
.mp-header-icons-right {
  white-space: nowrap;
}
.mp-header-icons-right .header-user-name {
  display: none;
}
@media (min-width: 400px) {
  .mp-header-icons-right .header-user-name {
    display: inline-block;
  }
}
.mp-header-icons-right i:hover {
  background-color: #111111;
}
.mp-header-icons-right button,
.mp-header-icons-right div {
  display: inline-block;
  color: white;
}
.mp-header-icons-right div {
  padding: 0 8px;
  line-height: 40px;
}
.mp-header-icons-right img {
  box-sizing: border-box;
  margin: 2px;
  width: 36px;
  height: 36px;
  vertical-align: top;
  border-radius: 20px;
  border: solid 2px #595959;
  cursor: pointer;
}
.mp-header-user-menu {
  position: absolute;
  right: 0;
  width: 160px;
  background-color: #333333;
  color: white;
  z-index: 999;
}
.mp-header-user-menu div,
.mp-header-user-menu a {
  display: block;
  padding: 0 8px;
  line-height: 40px;
  text-decoration: none;
  color: white;
  cursor: pointer;
}
.mp-header-user-menu div:hover,
.mp-header-user-menu a:hover {
  background-color: #111111;
}
.mp-header-user-menu div:visited,
.mp-header-user-menu a:visited {
  color: white;
}

.mp-image-input img {
  display: block;
  margin: 10px 0;
  border: solid 1px;
  text-align: center;
  height: 130px;
  width: 100px;
  font-size: 10px;
}

.mp-multi-select {
  display: flex;
  flex-wrap: wrap;
}
.mp-multi-select-option {
  margin: 0 8px 8px 0;
  min-width: 32px;
  padding: 8px;
  box-sizing: border-box;
  border: solid 1px black;
  user-select: none;
  cursor: pointer;
}
.mp-multi-select-option.selected {
  background-color: rosybrown;
}

row-component {
  display: block;
}
row-component span {
  display: inline-block;
  padding-right: 5px;
  width: 90px;
  text-align: right;
}
row-component .validation-error {
  margin-left: 90px;
  text-transform: uppercase;
  font-size: 8px;
  color: red;
}

.mp-setting-menu {
  display: inline-block;
  position: relative;
  vertical-align: top;
  cursor: pointer;
}
.mp-setting-menu i {
  line-height: inherit;
}
.mp-setting-menu-content-full-screen {
  position: fixed;
  width: 100vw !important;
  max-height: 100vh;
  background-color: white;
  top: 50% !important;
  left: 0 !important;
  transform: translateY(-50%);
  overflow-y: scroll;
  z-index: 9999;
}
.mp-setting-menu-content-full-screen-title {
  text-align: center;
  border-top: solid 1px white;
  background-color: #7a8086;
}
.mp-setting-menu-content {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: white;
  text-transform: uppercase;
  z-index: 9999;
}
.mp-setting-menu-content-item {
  box-sizing: border-box;
  padding: 0 8px;
  min-height: 40px;
  max-height: 40px;
  line-height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-top: solid 1px black;
  border-left: solid 1px black;
  border-right: solid 1px black;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  color: black;
}
.mp-setting-menu-content-item:last-child {
  border-bottom: solid 1px black;
}

.mp-tabs-navigation {
  display: flex;
  justify-content: space-between;
  background-color: #7a8086;
  user-select: none;
}
.mp-tabs-navigation .mp-tabs-navigation-right > div {
  display: inline-block;
}
.mp-tabs-navigation .mp-tabs-navigation-right i,
.mp-tabs-navigation .mp-tabs-navigation-right a {
  width: 40px;
  text-align: center;
  line-height: 39px;
  color: rgb(248, 241, 241);
}
.mp-tabs-navigation-left-item {
  display: inline-block;
  min-width: 32px;
  cursor: pointer;
  border-radius: 10px 10px 0 0;
  font-size: 11px;
  text-align: center;
  text-decoration: none;
  color: white;
  padding: 14px 4px;
}
.mp-tabs-navigation-left-item.selected {
  background-color: white;
  color: black;
}
.mp-tabs-navigation-right {
  display: inline-block;
  min-width: 32px;
  cursor: pointer;
  border-radius: 10px 10px 0 0;
  font-size: 11px;
  text-align: center;
  text-decoration: none;
  color: white;
}

.mp-tools-bar {
  display: flex;
  justify-content: space-between;
  text-align: right;
  color: white;
}
.mp-tools-bar-left > .mp-tools-bar-left-name:first-child {
  margin-left: 16px;
}
.mp-tools-bar-left, .mp-tools-bar-right {
  display: inline-block;
}
.mp-tools-bar-left-name, .mp-tools-bar-right-name {
  font-weight: 700;
  letter-spacing: 3px;
  text-transform: uppercase;
  line-height: 40px;
  vertical-align: middle;
}
.mp-tools-bar-description {
  padding: 0 15px;
}
.mp-tools-bar span {
  display: inline-block;
}

.font-red {
  color: #a20202;
}

.font-green {
  color: #509d50;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  background-color: #f8f6f8;
  overflow-y: scroll;
  min-width: 360px;
}
body #global-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 940px;
  min-height: 100svh;
  font-family: Helvetica, ui-serif;
  font-size: 15px;
  background-color: white;
}
body #global-container input,
body #global-container textarea,
body #global-container select {
  font-size: 16px;
  font-family: Helvetica, ui-serif;
}
body #global-container .global-page-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
body button {
  border: 0;
  padding: 5px 10px;
  display: inline-block;
  outline: none;
  cursor: pointer;
  color: white;
  background-color: #333333;
}
body button:hover {
  background-color: #111;
}
body button:disabled {
  background-color: gray;
}

:root {
  --rc-drag-handle-size: 12px;
  --rc-drag-handle-mobile-size: 24px;
  --rc-drag-handle-bg-colour: rgba(0, 0, 0, 0.2);
  --rc-drag-bar-size: 6px;
  --rc-border-color: rgba(255, 255, 255, 0.7);
  --rc-focus-color: #0088ff;
}

.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  max-width: 100%;
}
.ReactCrop *, .ReactCrop *::before, .ReactCrop *::after {
  box-sizing: border-box;
}
.ReactCrop--disabled, .ReactCrop--locked {
  cursor: inherit;
}
.ReactCrop__child-wrapper {
  overflow: hidden;
  max-height: inherit;
}
.ReactCrop__child-wrapper > img, .ReactCrop__child-wrapper > video {
  display: block;
  max-width: 100%;
  max-height: inherit;
}
.ReactCrop:not(.ReactCrop--disabled) .ReactCrop__child-wrapper > img, .ReactCrop:not(.ReactCrop--disabled) .ReactCrop__child-wrapper > video {
  touch-action: none;
}
.ReactCrop:not(.ReactCrop--disabled) .ReactCrop__crop-selection {
  touch-action: none;
}
.ReactCrop__crop-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}
.ReactCrop__crop-selection {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  cursor: move;
}
.ReactCrop--disabled .ReactCrop__crop-selection {
  cursor: inherit;
}
.ReactCrop--circular-crop .ReactCrop__crop-selection {
  border-radius: 50%;
}
.ReactCrop--circular-crop .ReactCrop__crop-selection::after {
  pointer-events: none;
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  border: 1px solid var(--rc-border-color);
  opacity: 0.3;
}
.ReactCrop--no-animate .ReactCrop__crop-selection {
  outline: 1px dashed white;
}
.ReactCrop__crop-selection:not(.ReactCrop--no-animate .ReactCrop__crop-selection) {
  animation: marching-ants 1s;
  background-image: linear-gradient(to right, #fff 50%, #444 50%), linear-gradient(to right, #fff 50%, #444 50%), linear-gradient(to bottom, #fff 50%, #444 50%), linear-gradient(to bottom, #fff 50%, #444 50%);
  background-size: 10px 1px, 10px 1px, 1px 10px, 1px 10px;
  background-position: 0 0, 0 100%, 0 0, 100% 0;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  color: #fff;
  animation-play-state: running;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes marching-ants {
  0% {
    background-position: 0 0, 0 100%, 0 0, 100% 0;
  }
  100% {
    background-position: 20px 0, -20px 100%, 0 -20px, 100% 20px;
  }
}
.ReactCrop__crop-selection:focus {
  outline: 2px solid var(--rc-focus-color);
  outline-offset: -1px;
}
.ReactCrop--invisible-crop .ReactCrop__crop-mask, .ReactCrop--invisible-crop .ReactCrop__crop-selection {
  display: none;
}
.ReactCrop__rule-of-thirds-vt::before, .ReactCrop__rule-of-thirds-vt::after, .ReactCrop__rule-of-thirds-hz::before, .ReactCrop__rule-of-thirds-hz::after {
  content: "";
  display: block;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
}
.ReactCrop__rule-of-thirds-vt::before, .ReactCrop__rule-of-thirds-vt::after {
  width: 1px;
  height: 100%;
}
.ReactCrop__rule-of-thirds-vt::before {
  left: 33.3333%;
  left: 33.3333333333%;
}
.ReactCrop__rule-of-thirds-vt::after {
  left: 66.6666%;
  left: 66.6666666667%;
}
.ReactCrop__rule-of-thirds-hz::before, .ReactCrop__rule-of-thirds-hz::after {
  width: 100%;
  height: 1px;
}
.ReactCrop__rule-of-thirds-hz::before {
  top: 33.3333%;
  top: 33.3333333333%;
}
.ReactCrop__rule-of-thirds-hz::after {
  top: 66.6666%;
  top: 66.6666666667%;
}
.ReactCrop__drag-handle {
  position: absolute;
  width: var(--rc-drag-handle-size);
  height: var(--rc-drag-handle-size);
  background-color: var(--rc-drag-handle-bg-colour);
  border: 1px solid var(--rc-border-color);
}
.ReactCrop__drag-handle:focus {
  background: var(--rc-focus-color);
}
.ReactCrop .ord-nw {
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  cursor: nw-resize;
}
.ReactCrop .ord-n {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: n-resize;
}
.ReactCrop .ord-ne {
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  cursor: ne-resize;
}
.ReactCrop .ord-e {
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  cursor: e-resize;
}
.ReactCrop .ord-se {
  bottom: 0;
  right: 0;
  transform: translate(50%, 50%);
  cursor: se-resize;
}
.ReactCrop .ord-s {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  cursor: s-resize;
}
.ReactCrop .ord-sw {
  bottom: 0;
  left: 0;
  transform: translate(-50%, 50%);
  cursor: sw-resize;
}
.ReactCrop .ord-w {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  cursor: w-resize;
}
.ReactCrop__disabled .ReactCrop__drag-handle {
  cursor: inherit;
}
.ReactCrop__drag-bar {
  position: absolute;
}
.ReactCrop__drag-bar.ord-n {
  top: 0;
  left: 0;
  width: 100%;
  height: var(--rc-drag-bar-size);
  transform: translateY(-50%);
}
.ReactCrop__drag-bar.ord-e {
  right: 0;
  top: 0;
  width: var(--rc-drag-bar-size);
  height: 100%;
  transform: translateX(50%);
}
.ReactCrop__drag-bar.ord-s {
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--rc-drag-bar-size);
  transform: translateY(50%);
}
.ReactCrop__drag-bar.ord-w {
  top: 0;
  left: 0;
  width: var(--rc-drag-bar-size);
  height: 100%;
  transform: translateX(-50%);
}
.ReactCrop--new-crop .ReactCrop__drag-bar, .ReactCrop--new-crop .ReactCrop__drag-handle, .ReactCrop--fixed-aspect .ReactCrop__drag-bar {
  display: none;
}
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
  display: none;
}
@media (pointer: coarse) {
  .ReactCrop .ord-n,
  .ReactCrop .ord-e,
  .ReactCrop .ord-s,
  .ReactCrop .ord-w {
    display: none;
  }
  .ReactCrop__drag-handle {
    width: var(--rc-drag-handle-mobile-size);
    height: var(--rc-drag-handle-mobile-size);
  }
}

.darts-speech-recognition {
  text-align: center;
}
.darts-speech-recognition button {
  margin: 8px;
  min-height: 40px;
}

