.mp-business-cards-page {
    .business-card {
        display: block;
        width: 160px;
        margin: 8px auto;
        padding: 8px;
        border-radius: 15px;
        background-color: rgb(117, 36, 36);
        color: white;
        text-align: center;
        text-decoration: none;
        line-height: 20px;

        .name {
            line-height: 20px;
            font-weight: bold;
            font-size: 16px;
        }

        &-single {
            box-sizing: border-box;
            width: 340px;
            font-size: 26px;
            line-height: 40px;

            .name {
                line-height: 50px;
                font-weight: bold;
                font-size: 36px;
            }
        }
    }
}
