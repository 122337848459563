.mp-accordion-item {
    &-header {
        line-height: 16px;
        padding: 12px;
        background-color: cornflowerblue;
        cursor: pointer;

        & > div {
            width: calc(100% - 16px);
            display: inline-block;
        }
    }

    &-content {
        line-height: 16px;
        padding: 0 12px;
        background-color: #a0c1f6;
    }
}
