.main {
    display: flex;
    margin: 8px auto;
    min-height: 116px;
    max-height: 116px;
    min-width: 1px;
    justify-content: space-between;
    width: fit-content;
    gap: 10px;

    .bar {
        background-color: gray;
        align-self: flex-end;
        width: 10px;
        min-height: 1px;
    }
}
