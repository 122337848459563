.mp-suggestion-list {
    position: relative;

    input {
        width: 100%;
        box-sizing: border-box;
        padding: 0 5px;
        height: 40px;
    }

    .suggestion {
        &-list {
            position: absolute;
            width: 100%;
            z-index: 1;

            &-item {
                position: relative;
                padding: 10px 35px 10px 5px;
                user-select: none;
                cursor: pointer;
                border: solid 1px;
                border-top: 0;
                box-sizing: border-box;
                background-color: rgb(250, 250, 200);

                &-count {
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &-on-list {
                    @extend .suggestion-list-item;
                    background-color: rgb(250, 250, 120);
                }
            }
        }
    }
}
