.mp-login-page {
    background-color: transparent;
    min-height: 100vh;

    &-container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: auto;
        padding: 32px;
        min-width: 300px;
        max-width: 300px;
        text-align: center;

        button {
            margin: 16px;
        }
    }
}
