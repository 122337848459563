@import '../../../sass/variables';
@import './recipesToolbar';

.recipes-grid {
    margin: 2px;
    display: grid;
    grid-template-columns: repeat(auto-fill, calc(50% - 1px));
    box-sizing: border-box;
    justify-content: center;
    grid-gap: 2px;

    @include breakpoint(desktop) {
        grid-template-columns: repeat(auto-fill, calc(25% - 2px));
    }

    &-item {
        display: flex;
        flex-direction: column;
        padding: 4px;
        border: solid 1px $global-gray-light;
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;

        div {
            display: grid;
            font-size: 12px;
            line-height: 16px;
            min-height: 40px;
            place-items: center;
            text-align: center;
            font-weight: 700;
            letter-spacing: 2px;
            text-transform: uppercase;

            &.missing-data {
                color: $dark-red;
            }
        }

        img {
            margin-top: auto;
            max-width: 100%;
            max-height: 100%;
            aspect-ratio: 1;
        }
    }
}
