.mp-file-input {
    @import '../../../../../sass/variables';
    $input-border-color: #8f8f9d;

    position: relative;
    margin: 8px;
    box-sizing: border-box;
    text-align: left;
    white-space: nowrap;

    i {
        position: absolute;
        top: 7px;
        left: 10px;
        width: 20px;

        svg {
            fill: white;
        }
    }

    &-title {
        @include input-title;
    }

    input {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        width: 100%;
        cursor: pointer;
        outline: none;

        &::file-selector-button {
            display: inline-block;
            box-sizing: border-box;
            padding: 5px 10px;
            width: 134px;
            height: 30px;
            text-align: right;
            border: 0;
            outline: none;
            background-color: $global-gray-dark;
            color: white;
            cursor: pointer;
            border-radius: 4px;

            &:hover {
                background-color: $global-gray;
            }
        }
    }

    &-uploading-progress {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 16px;
        margin: 8px 0;
        border: solid 1px black;
        font-size: 10px;

        div {
            box-sizing: border-box;
            height: 100%;
            background-color: #9afc98;
        }

        span {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
