.mp-costs-estimate-list-page {
    @import '../../../sass/variables';

    display: flex;

    a {
        display: inherit;
        padding: 11px;
        flex-grow: 1;
        background-color: $global-gray-very-light;
        margin-bottom: 1px;
        color: $global-gray-very-dark;
    }
}
