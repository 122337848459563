.search-component {
    display: flex;
    margin: 16px;
    justify-content: center;

    * {
        box-sizing: border-box;
    }

    input {
        display: inline-block;
        position: relative;
        width: 200px;
        line-height: 40px;
        margin: 0;
        height: 40px;
        vertical-align: middle;
    }

    button {
        font-size: 17px;
    }

    i {
        background-color: #333;

        svg {
            max-height: 20px;
            fill: white;
        }

        &:hover {
            background-color: black;
        }
    }
}
