.expenses-summary {
    text-align: center;

    &-title {
        margin: 8px;
        font-weight: 600;
        font-size: 17px;
    }

    &-items {
        display: grid;
        margin: 8px;
        grid-template-columns: repeat(auto-fill, 160px);
        justify-content: space-evenly;
        grid-gap: 5px;

        div {
            display: flex;
            justify-content: space-between;
            padding: 0 8px;
            background-color: #eeeeee;
            line-height: 30px;
            cursor: pointer;
        }
    }

    &-sum {
        margin: 8px;
    }
}
