@import '../../../sass/variables';

.item {
    display: block;
    margin: 4px;
    border: solid 1px black;
    border-radius: 5px;
    min-width: 75px;
    max-width: 75px;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    color: $global-gray-dark;

    svg {
        fill: $global-gray-dark;
        max-height: 40px;
        width: 100%;
    }

    @include breakpoint(desktop) {
        min-width: 175px;
        max-width: 175px;
    }

    i {
        margin: 8px 0 4px;
    }

    div {
        margin: 4px;
        line-height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
