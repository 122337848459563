.bucket-list-categories {
    text-align: center;

    .box {
        display: inline-block;
        position: relative;
        margin: 25px 15px 0 15px;
        width: 40%;
        height: 100px;
        border-radius: 10px;

        .icon {
            line-height: 100px;

            i {
                width: 40px;

                svg {
                    max-height: 40px;
                }
            }
        }

        .title {
            position: absolute;
            margin: 10px;
            width: 100%;
            height: 30px;
            bottom: -10px;
            left: -10px;
            border-radius: 0 0 10px 10px;
            text-transform: uppercase;
            font-family: 'Arial', sans-serif;
            font-weight: 600;
            line-height: 30px;
        }
    }
}
