.page {
    @import '../../../sass/variables';

    display: flex;
    flex-direction: column;
    padding: 8px;
    justify-content: space-between;
    max-width: 450px;

    .item {
        display: flex;
        gap: 8px;
        justify-content: space-between;
        border-bottom: 1px solid #eeeeee;
        padding: 2px 0;

        &:hover {
            background-color: lightgray;
        }
    }

    .past {
        color: $global-gray-very-light;

        .icon {
            filter: grayscale(100%) contrast(100%);
            opacity: 0.3;
        }

        &:hover {
            background-color: $global-gray-very-dark;
        }
    }

    .flex {
        display: flex;
        gap: 8px;
    }
}
