watched-movies {
    @import '../../../../sass/variables';
    .search {
        display: block;
        margin: 16px;

        @include breakpoint(desktop) {
            margin: auto;
            max-width: 600px;
        }
    }

    .watched-movies-list {
        div {
            font-size: 10px;
            text-align: left;
            padding: 3px 20px;
            border: solid 1px black;
            margin: 2px 0;

            a {
                display: inline-block;
                padding: 4px 0;
                color: black;
                text-decoration: none;
            }

            span {
                display: block;
            }
        }
    }
}
