@import '../../../sass/variables';

.cash-envelopes-page {
    &-header {
        margin: 8px;
        text-align: center;
        font-size: 16px;
    }

    &-grid {
        display: grid;
        grid-template-columns: 1fr;
        padding: 2px;
        box-sizing: border-box;
        justify-content: center;
        grid-gap: 2px;

        @include breakpoint(desktop) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        a {
            color: $global-gray-dark;
        }
    }
}
