.tab-container {
    height: 34px;
    font-size: 12px;
    font-family: Helvetica;
    background: rgb(104, 152, 180);

    .tab {
        float: left;
        padding: 10px 5px;
        margin: 0;
    }

    .active {
        background-color: rgb(173, 216, 230);
        border-radius: 10px 10px 0 0;
    }

    &:hover {
        cursor: pointer;
    }
}
