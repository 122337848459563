.mp-svg-icon {
    display: inline-block;
}

.mp-svg-button {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    vertical-align: middle;
    max-width: 40px;
    min-width: 40px;
    height: 40px;
    cursor: pointer;
    fill: white;

    svg {
        max-height: 15px;
        //max-width: 15px;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
