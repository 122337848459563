.filters {
    display: flex;
    margin: 16px 0;
    gap: 8px;
}

.filterLink {
    background-color: #f0ece1;
    line-height: 30px;
    padding: 0 4px;
    border-radius: 4px;
    color: black;
}

.filterSelected {
    outline: solid 2px black;
}

:global .people-container {
    display: flex;
    flex-direction: column;
    width: fit-content;
}

.person {
    label {
        display: flex;
        justify-content: space-between;
        gap: 8px;
        cursor: pointer;

        div {
            display: flex;
            margin: auto 8px;
        }

        input {
            width: 20px;
            height: 20px;
        }
    }
}
