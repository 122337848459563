.darts-score-board-title {
    @import 'vars';
    display: grid;
    justify-content: space-evenly;
    text-align: center;

    .player {
        padding: 4px;

        &-selected {
            background-color: $board-bg-color-selected;
        }

        &-name,
        &-points {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
        }

        &-points {
            font-size: 16px;
            text-align: center;
            line-height: 22px;
        }

        &-last-score {
            font-size: 11px;
        }
    }
}

.darts-score-board {
    @import 'vars';
    display: grid;
    justify-content: space-evenly;

    .player {
        padding: 4px;

        &-selected {
            background-color: $board-bg-color-selected;
        }

        .score-row {
            display: grid;
            grid-template-columns: 23% 23% 23% 31%;
            justify-content: space-evenly;
            margin-top: 2px;
            line-height: 18px;
            text-align: center;

            &.failed {
                div:nth-child(1),
                div:nth-child(2),
                div:nth-child(3) {
                    background-color: $point-bg-color-fail;
                    text-decoration: line-through;
                }
                div:nth-child(4) {
                    text-decoration: line-through;
                }
            }

            div:nth-child(1),
            div:nth-child(2),
            div:nth-child(3) {
                margin: 0 1px;
                border-radius: 10px;
                background-color: $point-bg-color;
                font-size: 10px;
            }
            div:nth-child(4) {
                font-size: 15px;
            }
        }
    }
}
