mp-movie-details {
    display: block;
    text-align: center;
    background-color: white;

    .title-1 {
        margin: 8px;
        font-size: 20px;
        font-weight: bold;

        a {
            text-decoration: none;
            color: black;
        }
    }

    img {
        max-width: 80%;
    }
}
