.shopping-list-view {
    @mixin center-slot {
        display: inline-block;
        width: 40px;
        text-align: center;
        line-height: 40px;
    }

    .change-item-count-modal {
        flex-grow: inherit;
        margin: auto;
        max-height: 40px;
        text-align: center;
        user-select: none;
    }

    .change-category-modal {
        .category-block {
            margin: 2px;
            border: solid 1px;
            line-height: 40px;
            cursor: pointer;

            &-selected {
                @extend .category-block;
                background-color: #eed2d2;
            }

            i {
                fill: black;
            }
        }
    }

    &-row {
        position: relative;
        line-height: 40px;
        height: 40px;
        border-bottom: solid 1px rgb(214, 212, 212);
        user-select: none;

        &-done {
            @extend .shopping-list-view-row;
            color: rgb(180, 180, 180);
        }

        &-check {
            @include center-slot;
            position: relative;
            height: 40px;

            input {
                position: absolute;
                top: 0;
                left: 0;
                width: 16px;
                height: 16px;
                padding: 0;
                margin: 12px 12px;
            }
        }

        &-name {
            display: inline-block;
            line-height: 40px;
            width: calc(100% - 120px);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        &-count {
            @include center-slot;
            color: gray;
            position: absolute;
            top: 0;
            right: 40px;
            cursor: pointer;
        }

        &-category {
            @include center-slot;
            position: absolute;
            right: 0;
            top: 0;
            cursor: pointer;

            i {
                fill: black;
            }
        }
    }
}
