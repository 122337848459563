.custom-setting-input {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    &-left {
        position: relative;
        line-height: 20px;
        width: 100%;

        span {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            color: $font-color;
        }
    }

    &-right {
        min-width: 128px;
        max-width: 128px;
        text-align: right;

        .mp-text-input,
        .mp-check-box {
            display: inline-block;
            margin-top: 0;
            margin-bottom: 0;
        }

        .mp-check-box {
            vertical-align: middle;
            margin-right: 10px;
        }

        .mp-text-input {
            min-width: 40px;
            max-width: 40px;
        }

        button {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}
