.mp-invoices-page {
    @import '../../../sass/variables';

    .invoices {
        margin: 8px;

        .invoice {
            margin-bottom: 8px;
            padding-bottom: 8px;
            border-bottom: solid 1px $global-gray-very-light;

            &-header {
                display: flex;
                justify-content: space-between;
            }

            &-details-1,
            &-details-2 {
                font-size: 12px;
            }

            &-details-1 {
                display: flex;
                justify-content: space-between;
                line-height: 18px;
            }

            &:last-child {
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }
}
