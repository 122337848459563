mp-recipes-toolbar {
    $filter-width: 110px;
    $filter-font-size: 13px;

    display: inline;

    .mp-recipes-toolbar {
        user-select: none;
        display: block;
        position: sticky;
        top: 0;
        z-index: 10;

        i {
            background-color: $global-gray-dark;
        }

        &__filter-select {
            width: $filter-width;
            height: 40px;
            margin: 0;
            font-size: $filter-font-size;
            text-align: left;
            vertical-align: top;
        }

        &__search {
            display: inline-block;
            height: 40px;
            width: calc(100% - 150px);
            background-color: $global-gray-dark;
            vertical-align: top;
            padding-top: 4px;
            box-sizing: border-box;

            input {
                box-sizing: border-box;
                height: 32px;
                padding-left: 2px;
                width: 100%;
            }
        }

        &__filters {
            position: absolute;
            margin: 0;
            padding: 0;

            a {
                text-decoration: none;

                :hover {
                    background-color: #111;
                }
            }

            li {
                font-size: $filter-font-size;
                width: $filter-width;
                padding: 10px 5px;
                box-sizing: border-box;
                background-color: $global-gray-dark;
                color: $global-white;
                list-style-type: none;
                text-align: center;
                cursor: pointer;
            }
        }
    }
}
