.fw-sr-element {
    @import '../../../../sass/variables';
    $border: solid 1px $global-gray-very-light;
    $height: 150px;

    position: relative;
    display: flex;
    margin: 16px auto;
    border-top: $border;
    border-bottom: $border;
    min-height: $height;
    max-height: $height;

    a {
        text-decoration: none;
        color: black;
    }

    @include breakpoint(desktop) {
        border: $border;
        max-width: 600px;
    }

    &-left {
        max-width: 105px;
        min-width: 105px;

        img {
            max-width: 105px;
            max-height: $height;
        }
    }

    &-right {
        position: relative;
        font-family: Lato, sans-serif;
        font-weight: 400;

        .fw-sr-badge {
            position: absolute;
            top: -8px;
            left: 8px;
            padding: 0 4px;
            white-space: nowrap;
            background: white;
            color: $rating-star-color;
            text-transform: uppercase;
            font-family: Helvetica, sans-serif;
            font-size: 14px;
            font-weight: 700;
        }

        &-title {
            margin: 12px 12px 0;
            font-size: 18px;
            line-height: 20px;
            max-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            //line-clamp: 2;
            //-webkit-line-clamp: 2;
            //-moz-box-orient: vertical;
            white-space: nowrap;
            max-width: calc(100vw - 140px);

            @include breakpoint(desktop) {
                max-width: 470px;
            }
        }

        &-year {
            margin: 6px 12px 0;
            font-size: 16px;
            white-space: nowrap;

            .filmweb-id-hidden {
                display: none;
            }
        }

        &-actors {
            margin: 6px 12px 0;
            font-size: 12px;

            a {
                margin-right: 8px;
                text-decoration: none;
                color: $global-gray-light;
                line-height: 14px;
                max-height: 14px;

                &:not(:last-child) {
                    &:after {
                        content: ',';
                    }
                }
            }
        }

        &-rating {
            position: absolute;
            display: flex;
            bottom: 10px;
            left: 12px;
            font-size: 22px;

            .community-rating {
                margin-left: 4px;
                padding-top: 4px;
                font-size: 8px;
                color: $global-gray-light;

                > span {
                    margin-left: 4px;
                    margin-right: 2px;
                }
            }

            i {
                width: 20px;
                margin-right: 8px;

                svg {
                    fill: $rating-star-color;
                }
            }
        }

        &-critics-rating {
            position: absolute;
            display: flex;
            bottom: 8px;
            left: 140px;

            span {
                box-sizing: border-box;
                border: solid 2px black;
                margin-right: 4px;
                padding: 4px 2px 2px;
                font-size: 18px;
            }

            .critics-rating {
                font-size: 8px;
                color: $global-gray-light;

                div:first-child {
                    margin-top: 6px;
                }
            }
        }

        &-absolute-items {
            position: absolute;
            display: flex;
            justify-content: flex-end;
            width: 80px;
            height: 40px;
            left: calc(100vw - 185px);
            bottom: 0;
            text-align: right;
            line-height: 40px;

            @include breakpoint(desktop) {
                left: 415px;
            }
        }

        &-upflix {
            padding-top: 2px;
            width: 40px;
            text-align: center;
            text-transform: uppercase;
            font-size: 10px;
            font-weight: 700;
            cursor: pointer;
        }

        &-reload-or-add {
            i {
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                cursor: pointer;

                svg {
                    fill: black;
                }

                &.rotate {
                    animation: rotate 1.5s linear infinite;
                }
            }

            @keyframes rotate {
                to {
                    transform: rotate(360deg);
                }
            }
        }
    }
}
