.items-list {
    display: flex;
    flex-direction: inherit;
    flex-grow: inherit;

    #bucketlist-items-list-view-color {
        display: flex;
        flex-direction: inherit;
        flex-grow: inherit;
    }

    .bucket-list-tab {
        text-align: center;

        button {
            width: 120px;
            color: black;
            border-radius: 5px;
        }
    }

    .bucket-list-item {
        text-align: left;
        margin: 5px 20px;
        padding: 5px;
        border-radius: 5px;

        &-label {
            display: flex;
            justify-content: space-between;

            .title {
                display: inline-block;
            }

            .details-button {
                display: inline-block;
                min-width: 40px;
                text-align: center;
            }

            .edit-button {
                display: inline-block;
                min-width: 40px;
                text-align: center;
            }
        }

        .details-container {
            display: none;

            &.visible {
                display: inline-block;
            }
            margin: 5px 0 0 0;
            color: gray;
        }
    }
}
