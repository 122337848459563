.recipe-description {
    margin: 8px 8px 40px;

    h3 {
        text-align: center;
    }

    div {
        margin: 8px 8px 8px 24px;
        display: list-item;
        list-style-type: disc;
    }
}
