@import './previewDatesView';
@import './datesList';

.mp-calendar-dates {
    margin: 10px auto;
    $size: 40px;
    $purple-1: rebeccapurple;
    $purple-2: rgb(121, 66, 175);
    $purple-3: rgb(221, 203, 238);

    display: block;
    position: relative;
    width: 315px;
    user-select: none;

    .calendar-navigation-bar {
        text-align: center;
        background-color: $purple-1;
        line-height: $size;
        color: white;

        span {
            display: inline-block;
            width: 150px;
        }

        a {
            display: inline-block;
            width: $size;
            height: $size;
            line-height: $size;
            cursor: pointer;
            color: $global-white;
            text-decoration: none;

            &:hover {
                background-color: $purple-2;
            }
        }
    }

    .calendar-grid {
        background-color: white;

        a {
            display: inline-block;
            position: relative;
            margin: 0;
            padding: 0;
            width: $size;
            height: $size;
            line-height: $size;
            text-align: center;
            color: black;
            cursor: pointer;
            text-decoration: none;
        }
    }

    .calendar-grid-row {
        display: flex;
        justify-content: space-around;
    }

    a.cell-event {
        color: $purple-2;
        font-weight: bold;

        &.cell-only-not-own {
            color: $purple-3;

            &:after {
                background: $purple-3;
            }
        }

        &:after {
            $half-space: 3px;
            position: absolute;
            content: '';
            bottom: 3px;
            left: $half-space;
            background: $purple-2;
            width: $size - 2 * $half-space;
            height: 4px;
            border-radius: 3px;
        }
    }

    a.cell-current-day {
        box-shadow: 0 0 0 2px red inset;
    }

    a.cell-other-month {
        color: #b6b4b4;
        font-weight: normal;

        &.cell-only-not-own {
            color: #b6b4b4;

            &:after {
                background: #efeded !important;
            }
        }
    }

    a.cell-other-month.cell-event:after {
        background-color: #c6c6c6;
    }
}
