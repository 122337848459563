.navigation {
    display: flex;
    width: fit-content;
    margin: auto;

    span {
        align-self: center;
    }

    .navBtn {
        display: inline-block;
        height: 1px;
        width: 40px;
    }
}
