.page {
    margin: 8px;
}

.rowsContainer {
    display: flex;
    flex-direction: column;
    width: fit-content;

    .row {
        display: flex;
        gap: 8px;
        justify-content: space-between;
    }
}
