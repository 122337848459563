@import '../../sass/variables';

.mp-discount-cards-page {
    display: flex;
    flex-wrap: wrap;
    padding: 8px;
    gap: 8px;
    justify-content: center;

    .card {
        display: flex;
        flex-direction: column;
        padding: 8px;
        border: solid 1px black;
        border-radius: 16px;
        width: 360px;
        height: 180px;
        overflow: hidden;
        text-align: center;
        cursor: pointer;

        &-red {
            color: #ce919b;
        }

        &-blue {
            color: royalblue;
        }

        &-title {
            font-size: 24px;
        }

        &-code {
            margin-top: auto;
        }
    }
}
