.mp-debtors-page {
    @mixin overflow {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .mp-tools-bar {
        margin-top: 4px;
        justify-content: center;

        i {
            fill: black;
        }
    }

    .mp-accordion-item {
        position: relative;
        margin: 4px 0;

        button {
            background-color: transparent;
            border: solid 1px white;
            border-radius: 5px;
        }

        i {
            fill: black;
        }

        .angle-down,
        .angle-up {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .accordion-custom-header {
        display: flex;
        justify-content: space-between;

        i {
            margin: -12px 0 -12px -12px;
        }

        span:nth-child(2) {
            @include overflow;
        }

        span:nth-child(3) {
            min-width: 85px;
        }

        span:nth-child(4) {
            min-width: 80px;
            text-align: right;
            margin-right: 12px;
        }
    }

    .mp-accordion-item-content {
        $transparent: rgba(255, 255, 255, 0);
        $gradient-color: #a0c1f6;

        overflow: auto;
        max-height: 300px;
        //background:
        //        /* Shadow covers */ linear-gradient($gradient-color 30%, $transparent),
        //    linear-gradient($transparent, $gradient-color 70%) 0 100%,
        //    /* Shadows */ radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
        //    radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
        background:
                /* Shadow covers */
            linear-gradient($gradient-color 30%, $transparent),
            linear-gradient($transparent, $gradient-color 70%) 0 100%,
            /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
            radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
        background-repeat: no-repeat;
        background-color: $gradient-color;
        background-size:
            100% 40px,
            100% 40px,
            100% 14px,
            100% 14px;
        background-attachment: local, local, scroll, scroll;
    }

    .debt-row {
        display: flex;
        line-height: 30px;

        &-value {
            min-width: 80px;
            text-align: right;
            margin-right: 8px;
        }

        &-description {
            @include overflow;
        }

        i {
            display: flex;
            justify-content: center;
            min-width: 30px;
            max-width: 30px;
            min-height: 30px;
            max-height: 30px;
            cursor: pointer;

            svg {
                width: 14px;
            }
        }
    }
}
