.recipe-view-page {
    @import './ingredientRow';

    user-select: none;

    .mp-tools-bar {
        svg {
            fill: black;
        }
    }

    &-portions {
        text-align: center;

        .mp-text-input {
            margin: auto;
            max-width: 40px;
        }

        span {
            margin: 8px;
        }
    }

    h3 {
        text-align: center;
    }
}
