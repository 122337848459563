@import '../../../sass/variables';

.time-off {
    &-header {
        margin: 8px;
    }

    .calendars-container {
        @include breakpoint(desktop) {
            padding: 0 16px;
        }

        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        > div {
            margin: 4px;
        }

        [data-cell] {
            > div.today {
                position: relative;
                outline: solid 2px red;
            }
        }
    }

    &-to-add {
        margin: 8px;

        & > * {
            margin-right: 8px;
        }
    }
}
