.mp-wines-ranking {
    @import '../../../sass/variables';

    &-content {
        @include breakpoint(desktop) {
            display: grid;
            grid-template-columns: repeat(auto-fill, calc(470px));
        }

        .wine-detail {
            display: flex;
            padding: 8px;
            border-bottom: solid 1px $global-gray-very-light;

            &-gray {
                * {
                    color: $global-gray-very-light !important;
                }
            }
            &-left {
                text-align: center;
                min-width: 100px;
                max-width: 100px;
                height: 180px;
                padding-right: 8px;

                img {
                    position: relative;
                    top: 50%;
                    transform: translate(0, -50%);
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            &-right {
                flex-grow: 1;
                overflow: hidden;

                a {
                    display: block;
                    margin-bottom: 8px;
                    text-align: center;
                    color: $link-color;
                    text-decoration: none;
                    text-transform: uppercase;
                }

                &-row {
                    span {
                        display: inline-block;
                        white-space: nowrap;
                        font-size: 12px;
                    }
                    span:first-child {
                        width: 125px;
                    }
                    &-string-value {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: calc(100% - 125px);
                    }
                }
            }
        }
    }
}
