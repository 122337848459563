.item {
    border-radius: 10px;
}

.row {
    display: flex;
    margin: 0 8px;
    justify-content: space-between;
    line-height: 24px;
    gap: 8px;

    .price {
        white-space: nowrap;
    }
}

.icon {
    max-width: 24px;
    min-width: 24px;
    height: inherit;

    svg {
        fill: black;
    }
}

.statusContainer {
    display: flex;
    justify-content: center;
    gap: 8px;
}

.status {
    opacity: 0.4;
    line-height: 24px;
}

.statusEdit {
    cursor: pointer;
    text-decoration: underline;
}

.statusSelected {
    opacity: 1;
    font-weight: 600;
}
