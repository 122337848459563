.rating-box {
    i {
        margin-left: -8px;
        cursor: pointer;
    }

    span {
        display: inline-block;
        vertical-align: middle;
    }
}
