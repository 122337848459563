table {
    white-space: nowrap;
    margin: 10px auto;
    user-select: none;

    tr {
        line-height: 18px;

        &.is-last-entry {
            background-color: #eed2d2;
        }

        &.is-selected {
            font-weight: 700;
        }

        cursor: pointer;

        td {
            padding: 2px 10px;

            &.align-right {
                text-align: right;
            }

            &.col-day-short-name {
                display: inline-block;
                padding-left: 2px;
                padding-right: 0;
                font-size: 10px;
                color: gray;
                vertical-align: middle;
                text-transform: uppercase;
                overflow: hidden;
            }
        }
    }
}
