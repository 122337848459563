.tile-items-settings {
    $selected-item-color: #3e91be;
    user-select: none;
    text-align: center;

    &-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, 40px);
        justify-content: space-between;
        grid-gap: 4px;
    }

    &-tiles-title {
        margin: 8px 0;
        color: $font-color;
    }

    &-tiles-preview {
        display: grid;
        grid-template-columns: repeat(auto-fill, 40px);
        justify-content: space-between;
        grid-gap: 4px;

        i {
            &.toolbar-item {
                &.selected-item {
                    background-color: $global-gray-dark !important;
                    border: solid 3px $selected-item-color !important;
                }
            }

            &.home-item {
                &.selected-item {
                    fill: $global-gray-dark !important;
                    border: solid 3px $selected-item-color !important;
                }
            }
        }
    }

    &-action-panel {
        margin: 8px 0 0;
        text-align: center;

        i {
            background-color: transparent !important;
            fill: $global-gray-dark !important;
        }
    }

    &-footer {
        margin-top: 16px;
        text-align: right;
    }

    i {
        background-color: $global-gray-dark;

        &:hover {
            background-color: $global-gray-very-dark;
        }

        &.toolbar-item {
            &.selected-item {
                background-color: $selected-item-color;
            }
        }

        &.home-item {
            border-radius: 5px;
            border: solid 1px $global-gray-dark;
            background-color: white;
            fill: $global-gray-dark;

            &.selected-item {
                border: solid 1px $selected-item-color;
                fill: $selected-item-color;
            }
        }
    }
}
