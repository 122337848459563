@import '../../sass/variables';

.mp-travel-page {
    $color-pink: #ffd7d7;
    $color-pink-dark: #c29090;

    .mp-payment-item {
        margin: 2px auto;
        max-width: 352px;
        background-color: $color-pink;

        &-header {
            display: flex;

            div {
                line-height: 40px;

                &:nth-child(1) {
                    flex-grow: 1;
                    margin: 0 8px;
                    text-transform: uppercase;
                }
                &:nth-child(2) {
                    min-width: 45px;
                    max-width: 45px;
                }
            }
        }
        &-content {
            padding: 8px;
        }
    }

    display: block;
    font-size: 10px;

    .mp-tools-bar {
        background-color: $color-pink-dark;

        a,
        button {
            background-color: $color-pink-dark;
        }
    }

    .table-container {
        overflow-x: auto;

        table {
            margin: 10px auto;
            border-collapse: collapse;
            background-color: $color-pink;
            border: solid 2px;

            th {
                background-color: $color-pink-dark;
                border: solid 1px;
            }

            tr {
                &.sum {
                    background-color: white;
                }
            }

            th,
            td {
                box-sizing: border-box;
                padding: 5px;
                border: solid 1px;

                &.col-date {
                    min-width: 68px;
                    text-align: center;
                }

                &.col-icon {
                    padding: 0;
                    min-width: 40px;
                    max-width: 40px;
                }
            }

            i {
                fill: black;

                &.not-editable {
                    fill: #a4a3a3;
                }
            }
        }
    }

    .travels-list {
        font-size: 15px;

        &-item {
            padding: 5px 10px;
            margin: 5px;
            background-color: $color-pink;
            border: solid 1px $color-pink-dark;

            span {
                display: inline-block;
            }

            a {
                display: flex;
                justify-content: space-between;
                text-decoration: none;
                line-height: 28px;
                overflow: hidden;
                white-space: nowrap;
                color: black;

                &:visited {
                    color: black;
                }
            }

            &-name {
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &-date {
                color: $global-gray-light;
                font-size: 9px;
            }
        }
    }
}
