.select-single-icon {
    margin: 8px;

    &-title {
        @include input-title;
    }

    i {
        box-sizing: border-box;
        cursor: pointer;

        &.selected {
            border: solid 2px black;

            &.select-item-disabled {
                border: solid 2px gray;
            }
        }

        &.select-item-disabled {
            svg {
                fill: gray;
            }
        }

        svg {
            fill: black;
        }
    }
}
