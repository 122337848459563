mp-games-page {
    display: block;

    select {
        width: 147px;
        height: 22px;

        @include breakpoint(desktop) {
            width: 606px;
        }
    }

    button {
        &.card-number {
            width: calc(10% - 2px);
            margin: 1px;

            &.done {
                background-color: limegreen;

                &:hover {
                    background-color: #37a237;
                }
            }
        }
    }
}
