.mp-text-input {
    @import '../../../../../sass/variables';

    position: relative;
    margin: 8px;

    &-title {
        @include input-title;
    }

    &-clear {
        position: absolute;
        right: 0;

        svg {
            fill: $global-gray-light;
        }
    }

    &.with-clear-button {
        input {
            padding-right: 40px;
        }
    }

    input {
        box-sizing: border-box;
        width: 100%;
        line-height: 40px;
        height: 40px;
        padding: 0 4px;
    }
}
