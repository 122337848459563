.mp-work-hours-page {
    @import '../../sass/variables';
    display: block;
    text-align: center;

    .mp-tabs-navigation-right {
        margin-right: 8px;
        line-height: 40px;
    }

    .mp-tools-bar-right {
        background-color: $global-gray-dark;

        i {
            &:hover {
                background-color: black;
            }
        }
    }

    .work-hours-navigation {
        margin: 8px auto;
        user-select: none;

        i {
            @include icon-button;
            margin: 0 8px;

            &.work-hours-navigation__button-disabled {
                color: gainsboro;
                background-color: $global-gray-light;
            }
        }

        &__label {
            display: inline-block;
            font-weight: 700;
            width: 185px;
        }
    }

    .earned {
        margin: 8px;
    }

    .table-container {
        overflow-x: auto;

        @import './ui/workHoursTable';
    }
}
