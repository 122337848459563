@import './variables';

.font-red {
    color: $font-color-red;
}

.font-green {
    color: $font-color-green;
}

html {
    scroll-behavior: smooth;
}

a {
    text-decoration: none;
}

body {
    margin: 0;
    background-color: #f8f6f8;
    overflow-y: scroll;
    min-width: 360px;

    #global-container {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: $page-max-width;
        min-height: 100svh;
        font-family: Helvetica, ui-serif;
        font-size: 15px;
        background-color: white;

        input,
        textarea,
        select {
            font-size: 16px;
            font-family: Helvetica, ui-serif;
        }

        .global-page-content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            overflow: hidden;
            margin: 0;
            padding: 0;
        }
    }

    button {
        border: 0;
        padding: 5px 10px;
        display: inline-block;
        outline: none;
        cursor: pointer;
        color: white;
        background-color: $global-gray-dark;

        &:hover {
            background-color: #111;
        }

        &:disabled {
            background-color: gray;
        }
    }
}
