.mp-spices-page {
    @import '../../../sass/variables';

    * {
        box-sizing: border-box;
        overflow: hidden;
    }

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    padding: 8px;
    gap: 8px;

    .spice {
        position: relative;
        display: flex;
        border-radius: 50%;
        width: 100%;
        aspect-ratio: 1;
        background-color: #df9c76;
        font-size: 12px;

        @include breakpoint(desktop) {
            font-size: 16px;
        }

        &.overdue {
            background-color: $global-red;
        }

        &.near-overdue {
            background-color: $light-red;
        }

        &-inner {
            $size: 80%;

            position: relative;
            display: flex;
            flex-direction: column;
            width: $size;
            height: $size;
            margin: auto;
            border-radius: inherit;
            background: black;
            color: white;
            text-align: center;
            overflow: hidden;

            div {
                max-width: 100%;
                margin: auto;
                padding: 4px;
                text-overflow: ellipsis;
            }

            span {
                position: absolute;
                bottom: 10%;
                left: 50%;
                transform: translateX(-50%);

                font-size: 10px;

                @include breakpoint(desktop) {
                    font-size: 14px;
                }
            }
        }
    }
}
