.mp-image-input {
    img {
        display: block;
        margin: 10px 0;
        border: solid 1px;
        text-align: center;
        height: 130px;
        width: 100px;
        font-size: 10px;
    }
}
