@import '../../../../sass/variables';

.form-input {
    white-space: nowrap;
    align-items: stretch;
    line-height: 16px;
    margin-bottom: 8px;

    border-bottom: solid 1px $global-gray-very-light;
    padding-bottom: 8px;

    &-hidden {
        display: none;
    }

    &-description {
        font-size: 12px;
        line-height: 16px;
        text-align: left;
    }

    &-input {
        position: relative;
        text-align: left;

        .validation-error {
            text-transform: uppercase;
            text-align: center;
            font-size: 8px;
            line-height: 10px;
            color: red;
        }

        input,
        textarea,
        select {
            margin: 1px;
            width: 100%;
            box-sizing: border-box;

            &[type='checkbox'] {
                width: 20px;
                height: 20px;
            }

            &[type='text'],
            &[type='time'],
            &[type='date'],
            &[type='date-time'] {
                height: 40px;
            }

            &[type='text'] {
                padding-right: 26px;

                &[inputmode='decimal'] {
                    padding-right: 40px;
                }
            }
        }

        select {
            height: 40px;
        }

        i.minus,
        i.xmark {
            position: absolute;
            top: 14px;
            cursor: pointer;
            width: 8px;
            fill: #5f6062;
        }

        i.xmark {
            right: 8px;
        }

        i.minus {
            right: 26px;
        }

        textarea {
            height: 100px;
        }
    }
}
