.mp-setting-menu {
    $border: solid 1px black;

    display: inline-block;
    position: relative;
    vertical-align: top;
    cursor: pointer;

    i {
        line-height: inherit;
    }

    &-content-full-screen {
        position: fixed;
        width: 100vw !important;
        max-height: 100vh;
        background-color: white;
        top: 50% !important;
        left: 0 !important;
        transform: translateY(-50%);
        overflow-y: scroll;
        z-index: 9999;

        &-title {
            text-align: center;
            border-top: solid 1px white;
            background-color: $global-gray-light;
        }
    }

    &-content {
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: white;
        text-transform: uppercase;
        z-index: 9999;

        &-item {
            box-sizing: border-box;
            padding: 0 8px;
            min-height: 40px;
            max-height: 40px;
            line-height: 40px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            border-top: $border;
            border-left: $border;
            border-right: $border;
            font-size: 12px;
            font-weight: bold;
            text-align: left;
            color: black;

            &:last-child {
                border-bottom: $border;
            }
        }
    }
}
