.mp-date-time-picker {
    @mixin span-position {
        position: relative;
        display: inline-block;
        padding: 0 5px;
    }

    margin: 8px 0;
    padding: 10px 0;
    user-select: none;
    font-size: 16px;

    i {
        width: 16px;
        cursor: pointer;
        border: solid transparent 7px;
        z-index: 1;
    }

    div {
        display: inline-block;
    }

    &-relative {
        position: relative;
    }

    &-add {
        position: absolute;
        top: -25px;
        left: 50%;
        transform: translateX(-50%);
    }

    &-view {
        @include span-position;
    }

    &-sub {
        position: absolute;
        bottom: -27px;
        left: 50%;
        transform: translateX(-50%);
    }
}
