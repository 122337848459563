.edit-item,
.add-item {
    display: flex;
    flex-direction: inherit;
    flex-grow: inherit;

    #bucketlist-view-color {
        display: flex;
        flex-direction: inherit;
        flex-grow: inherit;
    }

    $input-width: 200px;
    .edit-new {
        margin: 8px auto;
        max-width: 600px;
        min-width: 90%;
        text-align: center;

        .end-date-input {
            &.hidden {
                display: none;
            }
        }
    }
}
