.mp-files-page {
    @import '../../sass/variables';

    &-header {
        position: relative;
        margin: 8px;
        text-align: center;
        line-height: 40px;
        text-transform: uppercase;

        i {
            position: absolute;
            left: 0;
        }
    }

    &-files-container {
        &-file {
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            margin: 8px;
            height: 40px;
            border: solid 1px;
            line-height: 40px;
            flex-wrap: nowrap;

            &-name {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                min-width: 0;

                .folder {
                    cursor: pointer;
                }

                & > div {
                    display: inline;

                    a {
                        &:visited,
                        &:hover,
                        &:link {
                            color: rgb(108, 170, 15);
                        }

                        svg {
                            fill: rgb(108, 170, 15);
                        }
                    }

                    a[target] {
                        color: $global-gray-very-dark;
                    }

                    svg {
                        fill: rgb(211, 187, 76);
                    }
                }
            }

            &-details {
                white-space: nowrap;

                &-left {
                    display: inline-block;
                    font-size: 10px;
                    white-space: nowrap;
                    line-height: 1;
                    margin: 10px 4px;
                    text-align: center;
                }

                &-right {
                    display: inline-block;
                    vertical-align: top;

                    svg {
                        fill: red;
                    }

                    .pencil {
                        svg {
                            fill: $global-gray-very-dark;
                        }
                    }
                }
            }
        }
    }
}
