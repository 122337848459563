.mp-multi-select {
    display: flex;
    flex-wrap: wrap;

    &-option {
        margin: 0 8px 8px 0;
        min-width: 32px;
        padding: 8px;
        box-sizing: border-box;
        border: solid 1px black;
        user-select: none;
        cursor: pointer;

        &.selected {
            background-color: rosybrown;
        }
    }
}
