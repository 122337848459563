.mp-text-area {
    margin: 8px;

    &-title {
        @include input-title;
    }

    textarea {
        box-sizing: border-box;
        width: 100%;
        line-height: 13px;
        min-height: 60px;
        resize: none;
    }
}
