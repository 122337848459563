.mp-tabs-navigation {
    @mixin item {
        display: inline-block;
        min-width: 32px;
        cursor: pointer;
        border-radius: 10px 10px 0 0;
        font-size: 11px;
        text-align: center;
        text-decoration: none;
        color: white;
    }

    display: flex;
    justify-content: space-between;
    background-color: $global-gray-light;
    user-select: none;

    .mp-tabs-navigation-right {
        > div {
            display: inline-block;
        }

        i,
        a {
            width: 40px;
            text-align: center;
            line-height: 39px;
            color: $global-white;
        }
    }

    &-left {
        &-item {
            @include item;
            padding: 14px 4px;

            &.selected {
                background-color: white;
                color: black;
            }
        }
    }

    &-right {
        @include item;
    }
}
