.mp-checklist {
    @import './checkRow';

    $colorLight: #a0c1f6;
    $colorDark: #8da4af;
    $beige: #cbbfb3;

    user-select: none;

    .mp-tools-bar {
        background-color: $colorLight;

        &-left-name {
            background-color: $colorLight;
        }
    }

    &-names {
        div {
            border-bottom: solid 1px $beige;

            a {
                display: inline-block;
                width: 100%;
                margin: 0 8px;
                line-height: 40px;
                text-decoration: none;
                color: black;
            }
        }
    }
}
