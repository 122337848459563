.main {
    font-size: 12px;

    .title {
        margin: 8px;
        text-align: center;
    }

    table {
        width: 100%;
        margin-top: 16px;

        td:nth-child(4) {
            text-align: right;
        }
    }

    .sum {
        display: flex;
        justify-content: space-between;
        padding: 3px;
        border-top: solid 1px gray;
    }

    .um {
        margin-top: 32px;

        .umHeader {
            text-align: center;
            margin-bottom: 8px;
        }

        .umContent {
            display: flex;
            flex-direction: column;
            width: fit-content;
            margin: auto;
        }

        .umRow {
            margin: 4px;
            display: flex;
            gap: 8px;
            flex-grow: 1;
            justify-content: space-between;
        }
    }
}
