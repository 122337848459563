.mp-rating-stars {
    i {
        width: 16px;
        vertical-align: middle;

        svg {
            fill: black;
        }
    }
}
