@import '../../../sass/variables';

.recipe-edit-page {
    @import './ingredientEditRow';

    h4 {
        text-align: center;
        cursor: pointer;
    }

    &-ingredient-section {
        margin-bottom: 16px;
        text-align: center;

        input {
            max-width: 200px;
        }
    }
}
