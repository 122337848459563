.movie-info-skeleton {
    @import '../../../sass/commonStyles';
    $gray: $global-gray-light;
    padding: 8px 0;
    border-bottom: solid 1px $gray;

    @mixin skeleton {
        background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0));
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
    }

    &-top {
        display: flex;

        &-left {
            @include skeleton;
            width: 115px;
            height: 170px;
            margin: 8px;
            background-color: $gray;
        }

        &-right {
            flex-grow: 1;
            margin: 8px;

            div {
                background-color: $gray;
            }

            div:nth-child(1) {
                @include skeleton;
                max-width: 90%;
                height: 20px;
            }

            div:nth-child(2) {
                background-color: transparent;

                i {
                    @include skeleton;
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    margin: 8px 16px 8px 0;
                    background-color: $gray;
                }
            }

            div:nth-child(3) {
                @include skeleton;
                max-width: 70%;
                margin-top: 24px;
                height: 17px;
            }
        }
    }

    &-bottom {
        div {
            @include skeleton;
            height: 18px;
            max-width: 120px;
            margin: 8px auto;
            background-color: $gray;
        }
        section {
            display: grid;
            grid-template-columns: repeat(auto-fill, 95px);
            justify-content: space-evenly;
            grid-gap: 8px;
            margin-left: 8px;
            margin-bottom: 10px;

            i {
                @include skeleton;
                width: 99px;
                height: 68px;
                background-color: $gray;
            }
        }
    }

    @keyframes shine {
        to {
            background-position-x: -200%;
        }
    }
}
