.envelope-wrapper {
    $side-color: #636567;
    $bottom-color: #434646;
    $top-color: #3b3838;

    height: 335px;
    user-select: none;

    .envelope {
        position: relative;
        width: 280px;
        height: 180px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        margin-left: auto;
        margin-right: auto;
        top: 150px;
        background-color: $top-color;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    }

    .front {
        position: absolute;
        width: 0;
        height: 0;
        z-index: 3;
    }

    .flap {
        border-left: 140px solid transparent;
        border-right: 140px solid transparent;
        border-bottom: 82px solid transparent;
        border-top: 98px solid $top-color;
        transform-origin: top;
    }

    .pocket {
        border-left: 140px solid $side-color;
        border-right: 140px solid $side-color;
        border-bottom: 90px solid $bottom-color;
        border-top: 90px solid transparent;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .letter {
        position: relative;
        background-color: #fff;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 90%;
        top: 5%;
        border-radius: 6px;
        box-shadow: 0 2px 26px rgba(0, 0, 0, 0.12);

        &-value {
            font-size: 24px;
            font-weight: 600;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-image: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0) 25%,
                rgba(215, 227, 239, 0.7) 55%,
                rgba(215, 227, 239, 1) 100%
            );
        }

        & > div {
            padding: 4px;
            text-align: center;
        }
    }

    .open .flap {
        transform: rotatex(180deg);
        transition:
            transform 0.4s ease,
            z-index 0.6s;
        z-index: 1;
    }

    .close .flap {
        transform: rotatex(0deg);
        transition:
            transform 0.4s 0.6s ease,
            z-index 1s;
        z-index: 5;
    }

    .close .letter {
        transform: translatey(0px);
        transition:
            transform 0.4s ease,
            z-index 1s;
        z-index: 1;
    }

    .open .letter {
        transform: translatey(-60px);
        transition:
            transform 0.4s 0.6s ease,
            z-index 0.6s;
        z-index: 2;
    }
}
