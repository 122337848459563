@import '../../../sass/variables';

.ingredient-row {
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
    border-bottom: solid 1px $global-gray-very-light;

    &:last-child {
        border-bottom: none;
    }

    &-left {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
        line-height: 40px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .ingredient-name {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .ingredient-unit-value {
            min-width: 60px;
            text-align: right;
        }
    }

    &-right {
        max-width: 120px;
        min-width: 120px;
        text-align: right;

        span {
            display: inline-block;
            width: 40px;
            text-align: center;
        }
    }
}
