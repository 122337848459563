.mp-date-input {
    margin: 8px;
    text-align: left;

    &-title {
        @include input-title;
    }

    input {
        min-width: 132px;
        box-sizing: border-box;
        height: 40px;
        cursor: pointer;
    }
}
