.list {
    user-select: none;

    .date {
        margin: 8px;
        text-align: center;
        font-weight: 600;
    }

    .row {
        display: flex;
        gap: 8px;

        div {
            &:nth-child(1),
            &:nth-child(2) {
                width: 80px;
            }

            &:nth-child(2) {
                text-align: right;
            }
        }

        &:hover {
            background-color: whitesmoke;
        }
    }
}
