.mp-time-input {
    margin: 8px;

    &-title {
        @include input-title;
    }

    input {
        box-sizing: border-box;
        width: 100%;
        line-height: 40px;
        height: 40px;
        padding: 0 4px;
    }
}
