.table {
    width: 100%;
    margin: 0 auto;
    font-size: 10px;

    i {
        margin: 0 5px;
        width: 14px;
        cursor: pointer;

        svg {
            fill: black;
        }
    }

    th:nth-child(4),
    th:nth-child(5) {
        max-width: 35px;
    }

    tr {
        td,
        th {
            padding: 8px 3px;
            text-align: center;

            a {
                color: black;
                text-decoration: none;
                cursor: pointer;
            }
        }
    }

    tr:nth-child(odd) {
        background-color: lightgray;
    }

    .sum {
        text-align: right;
    }
}
